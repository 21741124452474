<template>
  <div id="app">
    <button @click="toggleAudio" :class="{'playing': isPlaying, 'paused': !isPlaying}" class="play-button">
      <img src="./assets/music.png" alt="Play">
    </button>
    
    <video 
      ref="video"
      width="320" 
      height="240" 
      controls
      @play="onVideoPlay"
      @pause="onVideoPause"
      @ended="onVideoEnded"
    >
      <source src="https://media.w3.org/2010/05/sintel/trailer.mp4" type="video/mp4">
    </video>
  </div>
</template>

<script>
export default {
  data() {
    return {
      audio: null,
      isPlaying: false,
      hasInteracted: false, // 判断是否有用户交互
    };
  },
  mounted() {
    this.audio = new Audio('http://fasun.oss-cn-hangzhou.aliyuncs.com/1670657914391_ll0qb4l7qe8.mp3');
    this.audio.loop = true; // 设置音乐循环播放

    // 监听用户的第一次交互事件
    document.addEventListener('touchstart', this.initAudio, { once: true });
    document.addEventListener('click', this.initAudio, { once: true });

    // 初始状态下设置图片为旋转状态
    this.isPlaying = true;
  },
  methods: {
    initAudio() {
      // 用户交互后播放音频
      this.audio
        .play()
        .then(() => {
          this.isPlaying = true;
          this.hasInteracted = true;
        })
        .catch((error) => {
          console.error('Error playing audio:', error);
        });
    },
    toggleAudio() {
      if (this.isPlaying) {
        this.audio.pause();
      } else {
        this.audio
          .play()
          .catch((error) => {
            console.error('Error playing audio:', error);
          });
      }
      this.isPlaying = !this.isPlaying;
    },
    onVideoPlay() {
      // 播放视频时暂停音乐
      if (this.isPlaying) {
        this.audio.pause();
        this.isPlaying = false;
      }
    },
    onVideoPause() {
      // 暂停视频时播放音乐
      if (!this.isPlaying && this.hasInteracted) {
        this.audio.play();
        this.isPlaying = true;
      }
    },
    onVideoEnded() {
      // 视频结束时播放音乐
      if (!this.isPlaying && this.hasInteracted) {
        this.audio.play();
        this.isPlaying = true;
      }
    }
  },
};
</script>

<style scoped>
.play-button {
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
}
.play-button.playing {
  animation: spin 1s linear infinite;
}
.play-button.paused {
  animation: none;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>